






















import { Component, Mixins } from 'vue-property-decorator'
import { AsyncView } from 'client-website-ts-library/plugins'
import { API, Config } from 'client-website-ts-library/services'
import { Office } from 'client-website-ts-library/types'
import ContentContainer from '@/components/Layout/ContentContainer.vue'
import ContactForm from '@/components/ContactForm.vue'

@Component({
  components: {
    ContactForm,
    ContentContainer,
  },
})
export default class Contact extends Mixins(AsyncView) {
  public office: Office | null = null;

  mounted() {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office
    })
    this.$emit('updateView')
  }
}
